<template lang="pug">
  div
    v-simple-table(dense)
      thead
        tr
          th Acct Code
          th Reason
          th.text-right Amount
          th Config
      tbody
        tr(v-for="item in voucherDetailList" :key="item.id")
          td {{ item.account_code.account_code }}
          td {{ item.reason }}
          td.text-right {{ item.amount.phpFormat() }}
          td
            v-btn(
              color="yellow darken-3"
              text
              small
              dense
              @click="$emit('update:edit', item)"
            )
              v-icon mdi-pencil
        tr
          th(colspan="2") Total
          td.text-right {{ voucherDetailList.sum('amount').phpFormat() }}
          td &nbsp;
</template>
<script>
import voucherRepository from '@/repositories/voucher.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [voucherDetailVars, voucherDetailVarNames] = requestVars({ identifier: 'voucher-detail' })

const voucherDetailHandler = new VueRequestHandler(null, voucherDetailVarNames)

export default {
  name: 'TableVoucherDetails',
  props: {
    edit: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      ...voucherDetailVars,
    }
  },
  computed: {
    voucherId () {
      return this.$route.params.voucherId
    },
  },
  created () {
    this.getVoucherDetails()
    this.initWebsockets()
  },
  methods: {
    initWebsockets () {
      const { echo } = this.$store.state.websocket
      const dbStoreEvent = ({ model, data }) => {
        if (model === 'VoucherDetail') {
          this.voucherDetailList.push(data)
        }
      }
      const dbUpdateEvent = ({ model, data }) => {
        if (model === 'VoucherDetail') {
          this.updateVoucherDetailListItem(data)
        }
      }
      echo.private('database.event')
        .listen('DBStoreEvent', dbStoreEvent)
        .listen('DBUpdateEvent', dbUpdateEvent)
    },
    updateVoucherDetailListItem (data) {
      this.voucherDetailList = this.voucherDetailList.map(item => {
        if (item.id === data.id) return Object.assign({ ...data })
        return Object.assign({ ...item })
      })
    },
    getVoucherDetails () {
      const handler = voucherDetailHandler
      const repository = voucherRepository.details
      handler.setVue(this)
      handler.execute(repository, [this.voucherId])
    },
  },
}
</script>